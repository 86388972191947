// store/index.js
import Vuex from 'vuex';
import axios from 'axios';
import { handleDeauth } from '@/utils/auth';

// Define initial state
const initialState = {
    user: null,
    projects: null,
    scopedProjectData: null,
    credit: null,
    plan: null,
    loading: false,
    stats: null,
};

export default new Vuex.Store({
    state: { ...initialState },
    mutations: {
        resetState(state) {
            Object.keys(state).forEach((key) => {
                state[key] = initialState[key];
            });
        },
        setUser(state, user) {
            state.user = user;
        },
        setProjects(state, projects) {
            state.projects = projects;

            localStorage.setItem('ownedProjects', JSON.stringify(projects));
        },
        setCredit(state, credit) {
            state.credit = credit;
        },
        setEmailCredits(state, newEmailCredits) {
            state.credit.email_credits = newEmailCredits;
        },
        setSMSCredits(state, newSMSCredits) {
            state.credit.sms_credits = newSMSCredits;
        },
        setPlan(state, plan) {
            state.plan = plan;
        },
        setStats(state, stats) {
            state.stats = stats;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        addProject(state, project) {
            state.projects.push(project);
        },
        addParticipant(state, participant) {
            state.scopedProjectData.participants.push(participant);
        },
        deleteParticipant(state, participantId) {
            // Remove the entry from the participants array
            const index = state.scopedProjectData.participants.findIndex(
                (participant) => participant.unique_parameter === participantId
            );

            if (index !== -1) {
                state.scopedProjectData.participants.splice(index, 1);
            }
        },
        deleteProject(state, projectId) {
            state.projects = state.projects.filter(
                (project) => project.project_id !== projectId
            );
        },
        setScopedProjectData(state, scopedProject) {
            state.scopedProjectData = scopedProject;
        },
        setProjectInvitationMessage(state, data) {
            if (data) {
                state.scopedProjectData.invitation_message = data;
            }
        },
        updateParticipantInList(state, editedParticipant) {
            const index = state.scopedProjectData.participants.findIndex(
                (participant) =>
                    participant.unique_parameter ===
                    editedParticipant.unique_parameter
            );

            if (index !== -1) {
                state.scopedProjectData.participants[index] = {
                    ...state.scopedProjectData.participants[index],
                    ...editedParticipant,
                };
            }
        },
        flushScopedProjectData(state) {
            state.scopedProjectData = null;
        },
    },
    actions: {
        logout({ commit }) {
            // You can also include any additional logic here, such as clearing local storage, making API calls, etc.
            commit('resetState'); // Call the resetState mutation
        },
        async fetchUserDetails({ commit, dispatch }) {
            try {
                commit('setLoading', true);
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.get(
                    `/api/index.php?endpoint=getUserData`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                const responseData = response.data.data;
                const userData = responseData.personal_data;
                const projectData = responseData.projects;
                const creditData = responseData.credits;
                const planData = responseData.plan_details;

                commit('setUser', userData);
                commit('setProjects', projectData);
                commit('setCredit', creditData);
                commit('setPlan', planData);
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchProjectUsers({ commit, dispatch }, { projectId, options }) {
            try {
                commit('setLoading', true);
                commit('flushScopedProjectData');
                options = options || {};
                const jwtToken = localStorage.getItem('userToken');
                const scopedProjectID = projectId;
                const response = await axios.get(
                    `/api/index.php?endpoint=getUserProject&storeAction=true&projectID=${scopedProjectID}${
                        options.mobile ? '&mobile=mandatory' : ''
                    }${options.email ? '&email=mandatory' : ''}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                const responseData = response.data.data;
                const projectData = responseData.projects[0];

                await dispatch('getStats', projectData);

                commit('setScopedProjectData', projectData);
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async addProject({ commit, dispatch }, formData) {
            try {
                commit('setLoading', true);
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.post(
                    '/api/index.php?endpoint=addProject',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                if (response.data.success && response.data.insertedData) {
                    // Commit the new project to the state
                    commit('addProject', response.data.insertedData);
                } else {
                    console.error('Failed to add project:', response.data);
                }
            } catch (error) {
                console.error('Error adding project:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteProject({ commit, dispatch }, projectId) {
            try {
                commit('setLoading', true);
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.post(
                    '/api/index.php?endpoint=deleteProject',
                    { projectId },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                if (response.data.success) {
                    commit('deleteProject', projectId);
                } else {
                    if (response.data.message == 'Token validation error') {
                        handleDeauth(this);
                        dispatch('logout');
                    }
                    console.error(
                        'Error deleting project:',
                        response.data.message
                    );
                }
            } catch (error) {
                console.error('Error deleting project:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async getStats({ commit }, projectData) {
            try {
                commit('setLoading', true);

                if (projectData) {
                    let projectStatistics = {
                        totalInvites: projectData.length || 0,
                        totalViews: 0,
                        totalAnswers: 0,
                        totalConfirmations: 0,
                        totalRefusals: 0,
                    };

                    // Calculate total views
                    let participantsData = projectData.participants;

                    participantsData.forEach((element) => {
                        projectStatistics.totalViews +=
                            element.invitation_views;
                        projectStatistics.totalAnswers +=
                            element.attendance_status ? 1 : 0;
                        projectStatistics.totalConfirmations +=
                            element.attendance_status == 'Attending' ? 1 : 0;
                        projectStatistics.totalRefusals +=
                            element.attendance_status == 'Not Attending'
                                ? 1
                                : 0;
                    });

                    commit('setStats', projectStatistics);
                }
            } catch (error) {
                console.error('Error Generating stats:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async setStatsSimple({ commit }, responses) {
            try {
                commit('setLoading', true);

                if (responses) {
                    let projectStatistics = {
                        totalResponses: responses.length || 0,
                        totalViews: 0,
                        totalAnswers: 0,
                        totalConfirmations: 0,
                        totalRefusals: 0,
                    };

                    // Calculate total views
                    let responseData = responses;

                    responseData.forEach((element) => {
                        projectStatistics.totalAnswers +=
                            element.attendance_status ? 1 : 0;
                        projectStatistics.totalConfirmations +=
                            element.attendance_status == 'Attending' ? 1 : 0;
                        projectStatistics.totalRefusals +=
                            element.attendance_status == 'Not Attending'
                                ? 1
                                : 0;
                    });

                    commit('setStats', projectStatistics);
                }
            } catch (error) {
                console.error('Error Generating stats:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async updateParticipant({ commit, dispatch }, editedParticipant) {
            try {
                const jwtToken = localStorage.getItem('userToken');

                const response = await axios.patch(
                    `/api/index.php?endpoint=updateParticipant`,
                    editedParticipant,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.success) {
                    commit('updateParticipantInList', editedParticipant);
                    return true; // Indicate success
                } else {
                    if (response.data.message == 'Token validation error') {
                        handleDeauth(this);
                        dispatch('logout');
                    }
                    console.error(
                        'Error updating participant details:',
                        response.data.message
                    );
                    return false; // Indicate failure
                }
            } catch (error) {
                console.error(
                    'Error updating participant details:',
                    error.response ? error.response.data : error.message
                );
                return false; // Indicate failure
            }
        },
        async addParticipant({ commit, dispatch }, formData) {
            try {
                commit('setLoading', true);
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.post(
                    `/api/index.php?endpoint=addParticipant`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                if (response && response.data && response.data.insertedRow) {
                    commit('addParticipant', response.data.insertedRow);
                }

                commit('setLoading', false);
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteParticipant(
            { commit, dispatch },
            { participantId, projectId }
        ) {
            try {
                commit('setLoading', true);
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.delete(
                    `/api/index.php?endpoint=deleteParticipant&projectId=${projectId}&participantId=${participantId}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                if (response.data.success) {
                    commit('deleteParticipant', participantId);
                }

                commit('setLoading', false);
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteResponse({ commit, dispatch }, { responseId, projectId }) {
            try {
                commit('setLoading', true);
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.delete(
                    `/api/index.php?endpoint=deleteResponse&projectId=${projectId}&responseId=${responseId}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.message == 'Token validation error') {
                    handleDeauth(this);
                    dispatch('logout');
                }

                // if (response.data.success) {
                //     commit('deleteResponse', responseId);
                // }

                commit('setLoading', false);
                return { success: true, responseId };
            } catch (error) {
                console.error('Error fetching user details:', error);
                return { success: false };
            } finally {
                commit('setLoading', false);
            }
        },
    },
    getters: {
        user: (state) => state.user,
        projects: (state) => state.projects,
        credit: (state) => state.credit,
        plan: (state) => state.plan,
        loading: (state) => state.loading,
        scopedProjectData: (state) => state.scopedProjectData,
        stats: (state) => state.stats,
    },
});
