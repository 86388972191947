<template>
    <UserTopNav :active="'projects'" />
    <div class="max-w-screen-xl w-full mx-auto">
        <router-link
            :to="{
                name: 'ProjectDetails',
                params: {
                    projectId: currentProjectId,
                    projectName: currentProjectName,
                },
            }"
            ><button
                class="my-10 font-bold bg-slate-500 hover:bg-slate-700 text-white shadow-xl transition px-6 py-2 rounded-full"
            >
                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                Inapoi la Proiect
            </button></router-link
        >

        <div
            v-if="scopedProjectData"
            class="border p-5 md:p-10 rounded-3xl bg-neutral-content relative"
        >
            <h2 class="font-extrabold text-2xl mb-20">Configuratii</h2>
            <div
                class="mt-14 md:mt-10 flex justify-between absolute top-0 left-0 w-full px-5 md:px-10"
            >
                <a
                    v-if="scopedProjectData.template_type == 'simple'"
                    class="btn btn-primary btn-sm md:btn-md"
                    target="_blank"
                    :href="`${getURL()}/#/ps/${scopedProjectData.project_id}/${
                        scopedProjectData.project_name
                    }`"
                    ><font-awesome-icon
                        icon="fa-solid fa-wand-magic-sparkles"
                    />
                    <span>Editeaza</span>
                    <span class="hidden md:block">Invitatia</span>
                </a>

                <a
                    v-else
                    class="btn btn-primary btn-sm md:btn-md"
                    target="_blank"
                    :href="`${getURL()}/#/public/${
                        scopedProjectData.project_id
                    }/edit`"
                    ><font-awesome-icon
                        icon="fa-solid fa-wand-magic-sparkles"
                    />
                    <span>Editeaza</span>
                    <span class="hidden md:block">Invitatia</span>
                </a>

                <button
                    v-if="scopedProjectData.template_type == 'simple'"
                    class="btn btn-accent btn-sm md:btn-md mr-auto ml-2 md:ml-5"
                    target="_blank"
                    @click="
                        copyToClipboard(
                            `${getURL()}/#/ps/${scopedProjectData.project_id}/${
                                scopedProjectData.project_name
                            }`
                        )
                    "
                >
                    <font-awesome-icon icon="fa-solid fa-copy" />
                    <span class="hidden md:block">Copiaza</span>
                    <span>Link</span>
                </button>

                <div class="md:ml-5 flex">
                    <DeleteProject
                        v-if="scopedProjectData && scopedProjectData.project_id"
                        :projectId="scopedProjectData.project_id"
                    ></DeleteProject>
                </div>
            </div>

            <div class="md:flex justify-between">
                <div class="text-left text-xl space-y-6 font-light">
                    <div
                        class="text-4xl font-medium md:text-5xl mb-10 md:font-extralight"
                    >
                        Evenimentul tau
                    </div>
                    <div>
                        Nume proiect:
                        <span class="font-bold">{{
                            scopedProjectData.project_name
                        }}</span>
                    </div>
                    <div>
                        Template ales:
                        <span class="font-bold">{{
                            scopedProjectData.project_template
                        }}</span>
                    </div>

                    <div>
                        Data eveniment:
                        <span class="font-bold">{{
                            scopedProjectData.project_date
                        }}</span>
                    </div>
                    <div>
                        <div class="flex">
                            <span class="">Activeaza reminder</span>
                            <input
                                type="checkbox"
                                checked="checked"
                                class="checkbox checkbox-primary ml-5"
                                v-model="autoReminder"
                                :disabled="
                                    scopedProjectData.template_type == 'simple'
                                "
                            />
                        </div>

                        <div class="join" v-if="autoReminder">
                            <VueDatePicker
                                v-model="autoReminderDate"
                                :enable-time-picker="false"
                                model-type="yyyy-MM-dd"
                                :format="formatDate"
                                :preview-format="formatDate"
                                :min-date="new Date()"
                                :max-date="
                                    new Date(scopedProjectData.project_date)
                                "
                                ignore-time-validation
                                locale="ro"
                                cancelText="Anuleaza"
                                selectText="Selecteaza"
                                input-class-name="input input-bordered join-item rounded-3xl date-input-class"
                                menu-class-name="date-menu-class"
                            ></VueDatePicker>
                            <button class="btn join-item">Salveaza</button>
                        </div>

                        <div class="text-sm">
                            Reminderul se va trimite automat in data selectata
                            catre toti invitatii care nu au raspuns.
                        </div>
                    </div>
                </div>

                <div class="mt-10 md:mt-0">
                    <InvitationMessage
                        v-if="
                            scopedProjectData &&
                            scopedProjectData.invitation_message
                        "
                        :projectId="scopedProjectData.project_id"
                        :userMessage="scopedProjectData.invitation_message"
                    />
                </div>
            </div>
        </div>

        <div class="my-10 px-5 md:px-0" v-if="user && scopedProjectData">
            <div class="my-5 font-light text-3xl">
                Esti gata sa trimiti invitatia?
            </div>
            <div
                @click="openSendEmailModal()"
                :class="{
                    'btn-disabled':
                        isEmailJobActive == true ||
                        user.user_active != 1 ||
                        scopedProjectData.template_type == 'simple',
                }"
                class="btn btn-secondary mr-5 mb-5 md:mb-0 w-full md:w-auto"
            >
                <font-awesome-icon icon="fa-solid fa-envelope" /> Trimite
                Invitatia prin Email

                <span
                    class="loading loading-bars loading-sm"
                    v-if="isEmailJobActive == true"
                ></span>
                <div
                    class="badge"
                    v-else
                    :class="
                        user.user_active == 1 ? 'badge-accent' : 'badge-outline'
                    "
                >
                    <font-awesome-icon icon="fa-solid fa-coins" /><span
                        class="pl-1"
                        >{{ credit.email_credits }}</span
                    >
                </div>
            </div>

            <div
                @click="openSendSMSModal()"
                :class="{
                    'btn-disabled':
                        isSMSJobActive == true ||
                        user.user_active != 1 ||
                        scopedProjectData.template_type == 'simple',
                }"
                class="btn btn-secondary w-full md:w-auto"
            >
                <font-awesome-icon icon="fa-solid fa-comment-sms" /> Trimite
                Invitatia prin SMS
                <span
                    class="loading loading-bars loading-sm"
                    v-if="isSMSJobActive == true"
                ></span>
                <div
                    v-else
                    class="badge"
                    :class="
                        user.user_active == 1 ? 'badge-accent' : 'badge-outline'
                    "
                >
                    <font-awesome-icon icon="fa-solid fa-coins" /><span
                        class="pl-1"
                    >
                        {{ credit.sms_credits }}</span
                    >
                </div>
            </div>
        </div>
    </div>
    <ModalComponent
        v-if="credit && isSendEmailModalOpen"
        :is-open="isSendEmailModalOpen"
        :containerClass="'w-11/12 max-w-5xl'"
        @close="closeSendEmailModal"
    >
        <!-- Content for Email Send Modal -->
        <h3 class="font-bold text-lg">Trimite invitatia prin Email</h3>
        <GetParticipantsListSendEmail
            v-if="isSendEmailModalOpen"
            :projectId="currentProjectId"
            :closeSendEmailModal="closeSendEmailModal"
            :isSendEmailModalOpen="isSendEmailModalOpen"
            :setEmailJobStatus="setEmailJobStatus"
        />
        <div class="modal-action">
            <form method="dialog">
                <button
                    @click="closeSendEmailModal"
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                    ✕
                </button>
            </form>
        </div>
    </ModalComponent>

    <ModalComponent
        :is-open="isSendSMSModalOpen"
        :containerClass="'w-11/12 max-w-5xl'"
        @close="closeSendSMSModal"
        v-if="credit && isSendSMSModalOpen"
    >
        <!-- Content for SMS Send Modal -->
        <h3 class="font-bold text-lg">Trimite invitatia prin SMS</h3>
        <GetParticipantsListSendSMS
            v-if="isSendSMSModalOpen"
            :projectId="currentProjectId"
            :closeSendSMSModal="closeSendSMSModal"
            :isSendSMSModalOpen="isSendSMSModalOpen"
            :setSMSJobStatus="setSMSJobStatus"
        />
        <div class="modal-action">
            <form method="dialog">
                <button
                    @click="closeSendSMSModal"
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                    ✕
                </button>
            </form>
        </div>
    </ModalComponent>
    <div v-if="loading">
        <LoadingComponent></LoadingComponent>
    </div>
</template>

<script>
import DeleteProject from '@/components/DeleteProject.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import GetParticipantsListSendEmail from '@/components/GetParticipantsListSendEmail.vue';
import GetParticipantsListSendSMS from '@/components/GetParticipantsListSendSMS.vue';
import eventBus from '@/utils/EventBus';
import { useToast } from 'vue-toastification';
import InvitationMessage from '@/components/InvitationMessage.vue';
import UserTopNav from '@/components/UserTopNav.vue';
import { mapGetters } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    name: 'AdminView',
    props: {
        projectId: {},
        projectName: {
            type: String,
        },
    },
    components: {
        DeleteProject,
        LoadingComponent,
        ModalComponent,
        GetParticipantsListSendEmail,
        GetParticipantsListSendSMS,
        InvitationMessage,
        UserTopNav,
        VueDatePicker,
    },
    data() {
        return {
            currentProjectId: null,
            currentProjectName: null,
            isSendEmailModalOpen: false,
            isSendSMSModalOpen: false,
            isEmailJobActive: false,
            isSMSJobActive: false,
            autoReminderDate: null,
            formattedDate: null,
            autoReminder: false,
        };
    },
    beforeUnmount() {
        eventBus.off('ProjectDeleted');
    },
    mounted() {
        this.$store.dispatch('fetchUserDetails');

        const projectId = this.$route.params.projectId;
        const options = {};
        this.$store.dispatch('fetchProjectUsers', {
            projectId,
            options,
        });
    },
    created() {
        // Access the current route from the Vue Router
        const currentRoute = this.$route;

        // Access the project ID from the route parameters
        this.currentProjectId = currentRoute.params.projectId;
        this.currentProjectName = currentRoute.params.projectName;
        // this.getProjectDetails();
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    computed: {
        ...mapGetters([
            'user',
            'projects',
            'plan',
            'credit',
            'loading',
            'scopedProjectData',
        ]),
    },
    methods: {
        copyToClipboard(textToCopy) {
            // Create a temporary DOM element
            const el = document.createElement('textarea');
            // Set its value to the text you want to copy
            el.value = textToCopy;
            // Append it to the document
            document.body.appendChild(el);
            // Select the text in the element
            el.select();
            // Use the modern Clipboard API to copy the text
            navigator.clipboard
                .writeText(el.value)
                .then(() => {
                    // Optionally, you can show a message or perform any other action to indicate that the text has been copied
                    // console.log('Text copied to clipboard: ' + textToCopy);
                    this.toast.success('Link-ul invitatiei a fost copiat!');
                })
                .catch(() => {
                    // console.error('Failed to copy text: ', err);
                })
                .finally(() => {
                    // Clean up by removing the temporary DOM element
                    document.body.removeChild(el);
                });
        },
        formatDate(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            this.formattedDate = `${day}/${month}/${year}`;
            return this.formattedDate;
        },
        getURL() {
            // Get the current URL
            const currentURL = window.location.href;

            // Use URL API to parse the URL
            const url = new URL(currentURL);

            return url.origin;
        },
        closeSendEmailModal() {
            this.isSendEmailModalOpen = false;
        },
        openSendEmailModal() {
            this.isSendEmailModalOpen = true;
        },
        setEmailJobStatus(status) {
            this.isEmailJobActive = status;
        },
        deductEmailCredits(burnedAmount) {
            this.credit.email_credits -= burnedAmount;
        },

        closeSendSMSModal() {
            this.isSendSMSModalOpen = false;
        },
        openSendSMSModal() {
            this.isSendSMSModalOpen = true;
        },
        setSMSJobStatus(status) {
            this.isSMSJobActive = status;
        },
        deductSMSCredits(burnedAmount) {
            this.credit.sms_credits -= burnedAmount;
        },
    },
};
</script>
