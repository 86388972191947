<template>
    <div class="mt-10 md:max-w-screen-xl mx-auto">
        <div
            class="w-full border border-gray-300 rounded-xl p-10 bg-white shadow-2xl"
        >
            <form
                class="flex flex-col md:flex-row items-center justify-center flex-wrap"
            >
                <label class="form-control w-full md:mt-10 md:w-1/2 md:pr-2">
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Nume</span
                        >
                    </div>
                    <input
                        v-model="name"
                        @change="handleInputChange()"
                        id="name"
                        name="name"
                        type="text"
                        autocomplete=""
                        required
                        requiredForced
                        class="input input-bordered w-full"
                        placeholder="Numele tau?"
                    />
                </label>

                <label class="form-control w-full md:w-1/2 mt-10 md:pl-2">
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Prenume</span
                        >
                    </div>
                    <input
                        v-model="surname"
                        @change="handleInputChange()"
                        id="surname"
                        name="surname"
                        type="text"
                        autocomplete=""
                        required
                        requiredForced
                        class="input input-bordered w-full"
                        placeholder="Prenumele tau?"
                    />
                </label>

                <label class="form-control w-full mt-10 md:w-1/3 md:pr-2">
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Adulti</span
                        >
                    </div>
                    <input
                        v-model="adults"
                        @change="handleInputChange()"
                        id="adults"
                        name="adults"
                        type="number"
                        required
                        autocomplete=""
                        min="1"
                        class="input input-bordered w-full"
                        placeholder="Cati adulti vor participa?"
                    />
                </label>

                <label class="form-control w-full md:w-1/3 mt-10 md:pl-2">
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Copii</span
                        >
                    </div>

                    <input
                        v-model="children"
                        id="children"
                        name="children"
                        type="number"
                        min="0"
                        autocomplete=""
                        class="input input-bordered w-full"
                        placeholder="Cati copii vor participa?"
                    />
                </label>

                <label class="form-control w-full md:w-1/3 mt-10 md:pl-2">
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Doresti meniu pentru copii?</span
                        >
                    </div>

                    <div class="flex pl-2 h-12 items-center">
                        <div class="mr-2">Da</div>
                        <input
                            v-model="childrenMenu"
                            type="radio"
                            name="childrenMenu"
                            class="radio"
                            value="true"
                        />
                        <div class="mr-2 ml-5">Nu</div>
                        <input
                            v-model="childrenMenu"
                            type="radio"
                            name="childrenMenu"
                            class="radio"
                            value="false"
                        />
                    </div>
                </label>

                <label
                    v-if="show.menu"
                    class="form-control w-full md:w-1/2 md:pr-2 mt-10 md:mt-10"
                >
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Meniu</span
                        >
                    </div>

                    <select
                        id="menuTypeSelect"
                        v-model="selectedMenuType"
                        @change="handleInputChange(selectedMenuType)"
                        class="select select-bordered w-full"
                    >
                        <option
                            v-for="menuType in menuTypes"
                            :key="menuType.value"
                            :value="menuType.value"
                        >
                            {{ menuType.label }}
                        </option>
                    </select>
                </label>

                <div
                    v-if="show.accommodation"
                    class="form-control w-full mt-10 text-left justify-between md:justify-normal flex-row flex-wrap md:w-1/2 md:pl-2 md:mt-10"
                >
                    <span
                        class="label-text font-semibold text-lg ml-1 mt-1 w-full"
                        >Doresti cazare?</span
                    >
                    <div class="form-control md:mr-10">
                        <label class="label cursor-pointer">
                            <span class="label-text mr-5">Da</span>

                            <input
                                v-model="accommodationChoice"
                                type="radio"
                                value="1"
                                id="yes"
                                name="accomodation"
                                class="radio checked:bg-blue-500"
                            />
                        </label>
                    </div>
                    <div class="form-control">
                        <label class="label cursor-pointer">
                            <span class="label-text mr-5">Nu</span>
                            <input
                                v-model="accommodationChoice"
                                type="radio"
                                value="0"
                                id="no"
                                name="accomodation"
                                class="radio checked:bg-red-500"
                            />
                        </label>
                    </div>
                </div>

                <label class="form-control w-full mt-10">
                    <div class="label">
                        <span class="label-text font-semibold text-lg"
                            >Vrei sa ne lasi un mesaj?</span
                        >
                    </div>

                    <textarea
                        class="textarea textarea-bordered"
                        v-model="message"
                        id="message"
                    ></textarea>
                </label>

                <div
                    v-if="!isButtonDisabled"
                    class="w-full mt-10 flex-col md:flex-row flex flex-wrap border-t border-slate-200 justify-center md:justify-end pt-5 md:space-x-10"
                >
                    <button
                        @click.prevent="() => submitResponse('submitNegative')"
                        type="submitNegative"
                        class="btn btn-outline"
                    >
                        Nu pot participa
                    </button>
                    <button
                        @click.prevent="() => submitResponse('submitPozitive')"
                        type="submitPozitive"
                        class="btn btn-primary mt-3 md:mt-0"
                    >
                        Confirm prezenta
                    </button>
                </div>
                <div v-else class="pt-10">
                    Raspunsul tau a fost inregistrat.
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
    data() {
        return {
            name: '',
            surname: '',
            adults: '',
            children: '',
            accommodationChoice: '',
            selectedMenuType: 'standard',
            menuTypes: [
                { value: 'standard', label: 'Standard' },
                { value: 'vegetarian', label: 'Vegetarian' },
            ],
            message: '',
            childrenMenu: false,
            type: null,
            show: {
                menu: true,
                accommodation: true,
            },
            isButtonDisabled: false,
            cookieName: 'cta_disabled',
        };
    },
    props: {
        projectId: {
            required: true,
        },
        invitationId: {
            type: String, // Adjust the type if it's not a string
            required: true,
        },
        isOwner: {
            type: Boolean, // Adjust the type if it's not a string
            required: true,
        },
        config: {
            required: false,
        },
    },
    mounted() {
        // Attach event listener to clear error class on input change
        const requiredElements = this.$el.querySelectorAll('[required]');
        requiredElements.forEach((element) => {
            element.addEventListener('input', this.clearErrorClass);
        });
    },

    beforeUnmount() {
        // Remove event listener when component is about to be destroyed
        const requiredElements = this.$el.querySelectorAll('[required]');
        requiredElements.forEach((element) => {
            element.removeEventListener('input', this.clearErrorClass);
        });
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    created() {
        // Check if the user is already authenticated (e.g., on page reload)
        this.isAuthenticated = this.checkAuthentication();
        // You may want to add a global event listener to listen for authentication changes
        window.addEventListener('storage', this.handleStorageEvent);

        this.type =
            this.$route.name === 'PublicInvitationSimple'
                ? 'simple'
                : 'complex';

        if (this.config && this.config.hideMenu) {
            this.show.menu = false;
        }

        if (this.config && this.config.hideAccommodation) {
            this.show.accommodation = false;
        }

        this.checkCookie(); // Check the cookie when the component is created
    },
    methods: {
        handleInputChange() {
            // Handle changes when the select element value changes
            // console.log('Selected:', element);
            // element.classList.add('border-green-600');
        },
        clearErrorClass(event) {
            // Clear the error class on input change
            const element = event.target;
            element.classList.remove('border-red-600');
        },
        async submitResponse(buttonType) {
            let willParticipate = true;

            if (buttonType == 'submitNegative') {
                willParticipate = false;

                const requiredElements =
                    this.$el.querySelectorAll('[requiredForced]');

                for (const element of requiredElements) {
                    const value = element.value.trim();

                    if (!value) {
                        // Display an error message
                        this.toast.error(
                            'Unele campuri sunt necesare, va rugam sa completati formularul'
                        );
                        // Add red border class for validation
                        element.classList.add('border-red-600');
                        return; // Prevent further execution
                    }
                }
            }

            if (willParticipate) {
                const requiredElements =
                    this.$el.querySelectorAll('[required]');

                for (const element of requiredElements) {
                    const value = element.value.trim();

                    if (!value) {
                        // Display an error message
                        this.toast.error(
                            'Unele campuri sunt necesare, va rugam sa completati formularul'
                        );
                        // Add red border class for validation
                        element.classList.add('border-red-600');
                        return; // Prevent further execution
                    }
                }
            }

            if (this.isOwner) {
                this.toast.info(
                    'Esti in modul editare, formualrul nu a fost trimis.'
                );
                return;
            }

            try {
                // Assuming you have form data available in Vue data properties
                const formData = {
                    name: this.name,
                    surname: this.surname,
                    adults: this.adults,
                    children: this.children,
                    childrenMenu: this.childrenMenu,
                    accommodationChoice: Number(this.accommodationChoice),
                    selectedMenuType: this.selectedMenuType,
                    message: this.message,
                    projectId: this.projectId,
                    uniqueParameter: this.invitationId
                        ? this.invitationId
                        : 'simple',
                    willParticipate: willParticipate,
                    type: this.type,
                };

                await axios
                    .post(
                        '/api/index.php?endpoint=addInvitationResponse',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    .then((result) => {
                        if (result.data.success) {
                            this.toast.success(
                                `Raspunsul tau a fost inregistrat cu succes!`
                            );

                            if (this.type === 'simple') {
                                this.$cookies.set(this.cookieName, true, 1); // Cookie expires in 1 day (24 hours)
                                this.isButtonDisabled = true;
                            }
                        } else {
                            this.toast.error(
                                `A aparut o eroare la trimiterea formularului!`
                            );
                        }
                    })
                    .catch(() => {
                        this.toast.error(
                            `A aparut o eroare la trimiterea formularului!`
                        );
                    });
            } catch (error) {
                console.error(
                    'A aparut o eroare la trimiterea formularului!',
                    error.response ? error.response.data : error.message
                );
            }
        },
        checkCookie() {
            // Check if the cookie exists
            const cookieExists = this.$cookies.get(this.cookieName);
            if (cookieExists) {
                this.isButtonDisabled = true;
            }
        },
        checkAuthentication() {
            // Check if the authentication token is present in localStorage
            const token = localStorage.getItem('userToken');
            return !!token; // Returns true if the token is present, otherwise false
        },
        handleStorageEvent(event) {
            // Listen for changes in localStorage (e.g., logout in another tab)
            if (event.key === 'userToken') {
                this.isAuthenticated = this.checkAuthentication();
            }
        },
    },
};
</script>

<style></style>
